export default {
  base: {
    dark: '#082333',
    mid: '#CCD9DF',
    light: '#EFF3F5',
    white: '#FFFFFF',
  },
  font: {
    main: '#0C344B',
    grey: '#8FA6B2',
  },
  highlight: {
    primary: {
      light: '#C3DCF1',
      dark: '#1B7CD5',
    },
    secondary: {
      light: '#C3EFDC',
      dark: '#26BC78',
    },
    tertiary: {
      light: '#FFD2B2',
      dark: '#FF8934'
    },
    quarternary: {
      light: '#F1D1EC',
      dark: '#A81B93'
    }
  }
}