import Typography from 'typography';
const typography = new Typography({
  headerFontFamily: ['Montserrat', 'sans-serif'],
  bodyFontFamily: ['Open Sans', 'sans-serif'],
  baseFontSize: '16px',
  overrideStyles: ({ adjustFontSizeTo, scale }, options) => ({
    h1: {
      ...adjustFontSizeTo('40px'),
      fontWeight: 700
    },
    h2: {
      ...scale(1),
      fontWeight: 700
    },
    h3: {
      ...scale(0.85),
      fontWeight: 700
    },
    h4: {
      ...scale(0.35),
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontWeight: 600,
      marginTop: '50px',
      borderLeft: '8px solid #FF8934',
      paddingLeft: '8px',
    },
    h5: {
      ...scale(0.35),
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontWeight: 400,
      lineHeight: '32px'
    },
    blockquote: {
      borderLeft: '6px solid #CCD9DF',
      paddingLeft: '6px',
      fontStyle: 'italic',
    }
  }),
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
