export default {
  breakpoints: {
    mobile: {
      width: '599px',
    },
    small: {
      width: '600px',
    },
    medium: {
      width: '900px',
    },
    large: {
      width: '1200px',
    },
    xlarge: {
      width: '1500px',
    },
  },
}