import React from 'react'
import Helmet from 'react-helmet'
import moment from 'moment';
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout/Layout'

import styled from 'styled-components'
import colorScheme from '../utils/colorScheme';
import settings from '../utils/settings'

import AdvertisedPost from '../components/AdvertisedPost';

const {
  breakpoints: { medium },
} = settings

const TitleDate = styled.p`
  font-size: 20px;
  color: ${colorScheme.font.grey};
  margin: 0;
`;

const ContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

const BlogImageContainer = styled.div`
  width: 100%;
  height: 325px;
  margin: 50px 0;
  background: ${props => props.image ? `url(${props.image}) no-repeat center center` : `whitesmoke`};
  background-size: cover;

  @media (min-width: ${medium.width}) {
    width: 90%;
    margin: 50px auto;
  }
`

const BottomSpacer = styled.div`
  height: 100px;

  @media (min-width: ${medium.width}) {
    height: 400px;
  }
`

const AdvertisedPosts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.filterAdvertisedPosts = this.filterAdvertisedPosts.bind(this)
  }

  filterAdvertisedPosts() {
    const currentPost = get(this, 'props.pageContext.slug')
    let advertisedPosts = get(this, 'props.data.allMarkdownRemark.edges')
    advertisedPosts = advertisedPosts.filter(post => {
      return post.node.fields.slug !== currentPost
    })
    if (advertisedPosts.length === 4) advertisedPosts.pop()
    return advertisedPosts
  }

  render() {
    const post = this.props.data.markdownRemark
    const advertisedPosts = this.filterAdvertisedPosts()
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const image = get(post, 'frontmatter.attachments[1].publicURL') || undefined;
    const date = get(post, 'frontmatter.date')
    const siteDescription = post.excerpt

    const formattedDate = moment(date);

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`${post.frontmatter.title} | ${siteTitle}`}
          style={[{
            "cssText": `
              html { background: #082333; }
            `
          }]}
        />
        <ContentWrapper>
          <TitleDate>
            {formattedDate.format('Do MMMM, YYYY')}
          </TitleDate>
          <h1>{post.frontmatter.title}</h1>
        </ContentWrapper>
        <BlogImageContainer image={image} />
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </ContentWrapper>
        <BottomSpacer />

       <AdvertisedPosts>
          {advertisedPosts.map(({ node }) => (
            <AdvertisedPost
              post={node}
              key={node.fields.slug}
            />
          ))}
        </AdvertisedPosts>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date
        attachments {
          publicURL
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 4) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
            heroBackgroundTint
            attachments {
              publicURL
            }
          }
        }
      }
    }
  }
`
