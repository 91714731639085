import React from 'react';
import styled from 'styled-components';
import moment from 'moment'
import { rhythm } from '../utils/typography';

const Container = styled.div`
  height: 360px;
  width: 100%;
  background: #082333;
  z-index: 2;
  position: relative;
  color: white;
  display: flex;
  align-items: center;
`;

const InnerContainer = styled.div`
  margin: 0 auto;
  max-width: ${rhythm(50)};
`;

class Footer extends React.Component {
  constructor(props) {
    super(props)
    const today = moment()
    this.state = {
      year: today.format('YYYY')
    }
  }
  render() {
    return (
      <Container>
        <InnerContainer>
          JDCOOKIE - {this.state.year}
        </InnerContainer>
      </Container>
    )
  }
}

export default Footer
