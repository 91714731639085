import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logoImage from '../assets/logo.svg';

import { rhythm, scale } from '../utils/typography';

const Logo = styled.img`
  width: 42;
`;

const MenuWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
`;

class Menu extends React.Component {
  render() {
    return (
      <MenuWrapper>
        <Link to="/">
          <Logo src={ logoImage } />
        </Link>
      </MenuWrapper>
    )
  }
}

export default Menu
