import React from 'react';
import styled from 'styled-components';
import Menu from '../Menu';
import Footer from '../Footer';
import { rhythm } from '../../utils/typography';
import colorScheme from '../../utils/colorScheme';
import settings from '../../utils/settings'

const {
  breakpoints: { mobile, small, medium, large, xlarge },
} = settings

const PageContainer = styled.div`
  margin: 0 auto;
  padding: 0;
  background: white;
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px ${rhythm(3 / 4)} 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 110px 430px auto;

  @media (min-width: ${medium.width}) {
    grid-template-rows: 150px 300px auto;
  }
`;

const BlogWrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px ${rhythm(3 / 4)} 0;
`;

const TopTriangle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: ${colorScheme.base.light} transparent transparent transparent;

  @media (max-width: ${mobile.width}) {
    border-width: 450px 500px 0 0;
  }

  @media (min-width: ${small.width}) {
    border-width: 750px 900px 0 0;
  }

  @media (min-width: ${medium.width}) {
    border-width: 850px 1200px 0 0;
  }

  @media (min-width: ${large.width}) {
    border-width: 950px 1500px 0 0;
  }

  @media (min-width: ${xlarge.width}) {
    border-width: 1250px 2500px 0 0;
  }
`;

const BottomTriangle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent ${colorScheme.base.dark} transparent;

  @media (max-width: ${mobile.width}) {
    border-width: 0 0 450px 500px;
  }

  @media (min-width: ${small.width}) {
    border-width: 0 0 650px 900px;
  }

  @media (min-width: ${medium.width}) {
    border-width: 0 0 750px 1200px;
  }

  @media (min-width: ${large.width}) {
    border-width: 0 0 850px 1500px;
  }

  @media (min-width: ${xlarge.width}) {
    border-width: 0 0 950px 3000px;
  }
`;

const TopWrapper = styled.div`
  position: relative;
  overflow: hidden;
  background: none;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    // leaving location and rootPath because I can use them for the triangle logic
    return (
      <PageContainer>
        <TopWrapper>
        { location.pathname === rootPath &&
        <React.Fragment>
            <TopTriangle />
            <ContentWrapper>
              <ContentContainer>
                <Menu />
                { children }
              </ContentContainer>
            </ContentWrapper>
          </React.Fragment>
        }

        { location.pathname !== rootPath &&
          <React.Fragment>
            <BottomTriangle />
            <ContentWrapper>
              <BlogWrapper>
                <Menu />
                { children }
              </BlogWrapper>
            </ContentWrapper>
          </React.Fragment>
        }
        </TopWrapper>
        <Footer />
      </PageContainer>
    )
  }
}

export default Template
