import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';
import colorScheme from '../utils/colorScheme';
import settings from '../utils/settings'
import moment from 'moment';

const {
  breakpoints: { medium },
} = settings

const Wrapper = styled.div`
  border: 1px ${colorScheme.base.mid} solid;
  border-radius: 6px;
  margin-bottom: 50px;
  width: auto;
  flex: 1 1 auto;
  background: white;

  @media (min-width: ${medium.width}) {
    flex: 0 1 31%;
    margin-right: 20px;
  }
`;

const InnerWrapper = styled.div`
  cursor: pointer;
`;

const HeroWrapper = styled.div`
  width: 100%;
  height: 200px;
  background: ${props => props.image ? `url(${props.image}) no-repeat center center` : colorScheme.highlight[props.tint].light};
  background-size: cover;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
`;

const HeroTitle = styled.h4`
  color: ${colorScheme.base.dark};
  padding-left: 0;
  margin-top: 0;
  border-left: none;
`;

const HeroDate = styled.p`
  color: ${colorScheme.font.grey};
  font-weight: 400;
  padding-left: 0;
  margin-top: 0;
  border-left: none;
  font-size: 15px;
`;

const FeatureDetails = styled.div`
  padding: 20px;
`;

class FeaturedPost extends React.Component {
  render() {
    const { post } = this.props;
    const tint = get(post, 'frontmatter.heroBackgroundTint');
    const title = get(post, 'frontmatter.title') || '';
    const image = get(post, 'frontmatter.attachments[0].publicURL') || undefined;
    const date = get(post, 'frontmatter.date') || '';
    const excerpt = get(post, 'excerpt');

    const formattedDate = moment(date);

    return (
      <Wrapper>
        <InnerWrapper
          onClick={ () => navigate(`${get(this.props, 'post.fields.slug')}`) }
          role="link"
        >
          <HeroWrapper tint={tint} image={image} />
          <FeatureDetails>
            <HeroTitle tint={tint}>
              { title }
            </HeroTitle>
            <HeroDate>{ formattedDate.format('Do MMMM, YYYY') }</HeroDate>
            <p dangerouslySetInnerHTML={{ __html: excerpt }} />
          </FeatureDetails>
        </InnerWrapper>
      </Wrapper>
    )
  }
}

FeaturedPost.propTypes = {
  post: PropTypes.object.isRequired
};

export default FeaturedPost
